import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  settingsPath: string;
  settings: any;

  constructor(private http: HttpClient) {}

  async getJSON(): Promise<any> {
    this.settings = await this.http.get('./assets/settings.json').toPromise();
    return this.settings;
  }
}
