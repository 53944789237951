import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { RequestCache } from '@app/services/cache/request-cache.service';
import { environment } from '@env/environment';

@Injectable()
export class ApiCachingInterceptor implements HttpInterceptor {

    cachableRootUrl = `${environment.apiUrl}/api`;
    nonCachableUrls = environment.apiUrlExcludedFromCache;

    constructor(private cache: RequestCache) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRequestCachable(req)) {
            return next.handle(req);
        }

        // Try to load from cache
        const cachedResponse = this.cache.get(req);
        if (cachedResponse != null) {
            return of(cachedResponse);
        }

        // Otherwise continue request, and store it
        return next.handle(req).pipe(
            tap(res => {
                if ((res instanceof HttpResponse) && this.isResponseCachable(res)) {
                    this.cache.put(req, res);
                }
            })
        );
    }

    private isRequestCachable(req: HttpRequest<any>) {
        return (req.method === 'GET')
            && req.url.startsWith(this.cachableRootUrl)
            && this.nonCachableUrls.filter(x => req.url.startsWith(x)).length == 0;
    }

    private isResponseCachable(res: HttpResponse<any>) {
        return res.status === 200; // Only cache successfull response
    }
}