import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as components from '@app/components';
import { AuthenticationGuard } from '@app/services/auth/authentication.guard';

const routes: Routes = [
  { path: 'login', component: components.AuthLoginComponent },
  { path: '', component: components.MainHomeComponent, canActivate: [AuthenticationGuard], },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
