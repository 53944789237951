import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";

import { AuthenticationService } from "@app/services";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  isAuthenticated$: Observable<boolean>;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.isAuthenticated$ = this.authenticationService.getAuthenticatedStatus();
    this.isAuthenticated$.subscribe(isAuthenticated => {
      if (!isAuthenticated) {
        this.router.navigate(["/"], { replaceUrl: true });
      }
    });

    // Set general Datatables settings
    var generalDtOptions: DataTables.Settings = {
      language: {
        lengthMenu: "Afficher _MENU_ éléments",
        search: "<strong>Rechercher : </strong>",
        zeroRecords: "Aucun résultat trouvé",
        infoEmpty: "Aucun prix à afficher",
        infoFiltered: "",
        paginate: {
          previous: "Précédent",
          next: "Suite",
          first: "",
          last: ""
        },
        info:
          "Affichage de <strong>_START_</strong> à <strong>_END_</strong>. <span class='badge badge-warning'>Total :<strong> _TOTAL_</strong></span>"
      }
    };
    $.extend(true, $.fn.dataTable.defaults, generalDtOptions);
  }
}
