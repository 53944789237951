import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, take, switchMap, finalize, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { AuthenticationService } from '@app/services/auth/authentication.service';
import { Logger } from '@app/services/logger.service';

const log = new Logger('AuthenticationInterceptor');

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

    private readonly tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private readonly authenticationService: AuthenticationService;

    private isRefreshingToken: boolean = false;

    constructor(private injector: Injector) {
        this.authenticationService = this.injector.get(AuthenticationService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!req.url.startsWith(environment.apiUrl)) {
            return next.handle(req);
        }

        // Update current request with authentication token
        const newReq = this.updateReqWithToken(req, this.authenticationService.authenticationToken);

        // Then perform the request, and manage errors
        return next.handle(newReq).pipe(
            catchError(e => {
                // Try to handle 401 with refresh token
                if (e instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>e).status) {
                        case 400:
                            return this.handle400Error(e);
                        case 401:
                            return this.handle401Error(req, next);
                    }
                }
                return throwError(e);
            })
        );
    }

    private handle400Error(errorResponse: HttpErrorResponse) {
        log.info("Handle 400 response");

        // In case we are not able to refresh the token (more than the expire time, or blacklisted)
        // We logout the user
        if (errorResponse.error && errorResponse.error.reason === 'invalid_grant') {
            log.warn("Invalid grant, user is logout");
            return this.logoutUser();
        }

        return throwError(errorResponse);
    }

    private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        log.info("Handle 401 response");

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap(newCred => {
                    log.debug("Token has been refreshed, continue current request with new token");

                    this.tokenSubject.next(newCred.token);
                    return next.handle(this.updateReqWithToken(req, newCred.refreshToken, true));
                }),
                catchError(error => {
                    log.error("Failed to refresh token", error);

                    // If there is an exception calling 'refreshToken', we logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    log.debug("Token has been refreshed, continue holded request with new token");

                    return next.handle(this.updateReqWithToken(req, token, true));
                })
            )
        }
    }

    private updateReqWithToken(req: HttpRequest<any>, token: string | null, clean: boolean = false): HttpRequest<any> {
        if (token != null) {
            return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }, url: clean ? req.url.replace('?return=401', '') : req.url });
        }
        return req;
    }

    private logoutUser() {
        return this.authenticationService.logout()
            .pipe(mergeMap(_ => throwError("Logout")));
    }
}
