import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '@app/services';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  title = 'ApplianceBerryCert';

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
  }

  logout() {
    // The redirect to login will be done by the app component
    this.authenticationService.logout();
  }

  get username(): string | null {
    const userInfo = this.authenticationService.userInfo;
    return userInfo ? userInfo.username : null;
  }
}
