import { Injectable } from '@angular/core';
import { Observable, of, pipe, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { CredentialsService } from './credentials.service';
import { Credentials, UserInfo } from '@app/shared/models';
import { ApiService } from '@app/services/api.service';
import { Logger } from '@app/services/logger.service';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

const log = new Logger('AuthenticationService');

/**
 * Provides a base for authentication workflow.
 */
@Injectable()
export class AuthenticationService {
  private _isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _credentials: Credentials | null = null;

  constructor(private credentialsService: CredentialsService, private apiService: ApiService) {
    this.credentialsService.getCredentials()
      .subscribe(x => {
        const isAuthenticated = x != null;

        log.debug(`Credentials updated - User is ${!isAuthenticated ? 'NOT' : ''} authenticated`);

        this._isAuthenticated$.next(isAuthenticated);
        this._credentials = x;
      });
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user information.
   */
  login(context: LoginContext): Observable<UserInfo> {
    log.debug(`Call login`);

    return this.apiService.login(context.username, context.password).pipe(
      map(cred => {
        // Extra data can be extracted from JWT token and be stored on UserInfo object
        const userInfo = { username: context.username };
        const credentials = { userInfo, token: cred.token, refreshToken: cred.refreshToken };
        this.credentialsService.setCredentials(credentials, context.remember || false);

        log.info(`User logged in successfully`);

        return userInfo;
      })
    );
  }

  /**
   * Refresh the token of the currently authenticated user.
   * @return The new user token
   */
  refreshToken(): Observable<Credentials> {
    log.debug(`Call refresh token`);

    const currentCredentials = this._credentials;
    if (currentCredentials == null || !currentCredentials.refreshToken) {
      return Observable.throw('Cannot refresh token from invalid credentials');
    }

    return this.apiService.refreshToken(currentCredentials.refreshToken).pipe(
      map(cred => {
        const newCred = { userInfo: currentCredentials.userInfo, token: cred.token, refreshToken: cred.refreshToken };
        this.credentialsService.setCredentials(newCred);

        log.info(`Token refreshed successfully`);

        return newCred;
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials(null);

    log.info(`Logged out successfully`);

    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return this._credentials != null;
  }

  /**
   * Returns the user information if authenticated.
   * @return Info of the user is authenticated, else null.
   */
  get userInfo(): UserInfo | null {
    return this._credentials ? this._credentials.userInfo : null;
  }

  /**
   * Returns the current JWT token if authenticated.
   * @return JWT token if the user is authenticated, else null.
   */
  get authenticationToken(): string | null {
    return this._credentials ? this._credentials.token : null;
  }

  /**
   * Gets an observable based on user authentication.
   * @return True if the user is authenticated, False if it's not.
   */
  getAuthenticatedStatus(): Observable<boolean> {
    return this._isAuthenticated$;
  }
}
