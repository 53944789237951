import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface Tag {
  id: string;
  value: string;
}

@Component({
  selector: 'app-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
})
export class TagsInputComponent implements OnInit {
  @Input() placeholder: string;
  @Input() name: string;
  @Input() multipleInputTextValue: string;
  @Input() tagsValue: string[] = [];
  @Output() onchangeTags = new EventEmitter<string[]>();
  @Output() onchangeTagInput = new EventEmitter<string>();
  tags: Tag[] = [];
  inputText = '';
  constructor() {}

  ngOnInit(): void {
    this.tagsValue.forEach((t) => {
      this.tags.push({ id: t, value: t });
    });
  }

  onItemAdded(tag: Tag) {
    this.tagsValue.push(tag.value);
    this.onchangeTags.emit(this.tagsValue);
  }

  onItemRemoved(tag: Tag) {
    this.tagsValue = this.tagsValue.filter((t) => t !== tag.value);
    this.onchangeTags.emit(this.tagsValue);
  }

  onItemChanged(tag: string) {
    this.onchangeTagInput.emit(tag);
  }
}
