import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize } from 'rxjs/operators';

import { AuthenticationService, Logger } from '@app/services';
import { environment } from '@env/environment';
import { NoNavBarComponent } from '@app/components/no-navbar.component';

const log = new Logger('AuthLoginComponent');

@Component({
  selector: 'auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent extends NoNavBarComponent implements OnInit {
  title = 'ApplianceBerryCert';
  version: string = environment.version;
  error: string | null = null;
  loginForm: FormGroup;
  isLoading = false;

  constructor(
    @Inject(DOCUMENT) document: Document,
    renderer: Renderer2,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService) {
    super(document, renderer);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.createForm();
  }

  login() {
    this.isLoading = true;
    this.authenticationService.login(this.loginForm.value)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        credentials => {
          log.debug(`${credentials.username} successfully logged in`);
          this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
        },
        error => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }

}
