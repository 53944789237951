import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


// 3rd parties
import { DataTablesModule } from "angular-datatables";
import { MomentModule } from "ngx-moment";
import "moment/locale/fr";

// App
import { AppRoutingModule } from "@app/app-routing.module";
import { AppComponent } from "@app/app.component";
import * as components from "@app/components";
import {
  AuthenticationGuard,
  CredentialsService,
  AuthenticationService,
  RequestCache,
  ApiService
} from "@app/services";
import { AuthenticationInterceptor } from "@app/services/auth/auth-http.interceptor";
import { ApiCachingInterceptor } from "@app/services/cache/api-caching-http.interceptor";
import { AuthLoginComponent, MainFooterComponent, MainHeaderComponent, MainHomeComponent } from "@app/components";
import { LoaderComponent, TagsInputComponent } from "./shared/components";
import { TagInputModule } from "ngx-chips";
import { AppSettingsService } from "./services/app.settings.service";
import { DownloadFileComponent } from './components/download-file/download-file.component';

@NgModule({
  declarations: [
    AppComponent,
    MainFooterComponent,
    MainHeaderComponent,
    MainHomeComponent,
    AuthLoginComponent,
    LoaderComponent,
    TagsInputComponent,
    DownloadFileComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    MomentModule,
    TagInputModule,
  ],
  providers: [
    AuthenticationGuard,
    CredentialsService,
    AuthenticationService,
    RequestCache,
    ApiService,
    AppSettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiCachingInterceptor, multi: true },
    { provide: 'API_URL', useFactory: () => document.location.origin },
  ],
  bootstrap: [AppComponent],
  entryComponents:[DownloadFileComponent]
})

export class AppModule {}
