import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  copyright: string;

  constructor() { }

  ngOnInit() {
    const copyrightName = 'ApplianceBerryCert';
    const copyrightDate = new Date().getFullYear();
    this.copyright = `${copyrightName} - ${copyrightDate}`;
  }

}
