import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Logger } from '@app/services/logger.service';
import { Credentials } from '@app/shared/models';

const credentialsKey = 'credentials';
const log = new Logger('CredentialsService');

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable()
export class CredentialsService {
  private _credentials$: BehaviorSubject<Credentials | null> = new BehaviorSubject<Credentials | null>(null);
  private _shouldRemember = false;

  constructor() {
    try {
      const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
      if (savedCredentials) {
        const parsedCredentials = JSON.parse(savedCredentials);
        this._credentials$.next(parsedCredentials);
      }
    } catch (e) {
      log.warn('Failed to restore saved credentials', e);
    }
  }

  /**
   * Gets the user credentials as observable.
   */
  getCredentials(): Observable<Credentials | null> {
    return this._credentials$;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials: Credentials | null, remember?: boolean) {
    if (remember != undefined) {
      this._shouldRemember = remember;
    }

    log.info(credentials ? 'Store credentials' : 'Reset credentials');
    this._credentials$.next(credentials || null);

    if (credentials) {
      const storage = this._shouldRemember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}
