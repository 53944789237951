import { DOCUMENT } from '@angular/common';
import { Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

export abstract class NoNavBarComponent implements OnInit, OnDestroy {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
    ) { }

    ngOnInit(): void {
        this.renderer.addClass(this.document.body, 'no-navbar');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(this.document.body, 'no-navbar');
    }
}
