import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from "@angular/core";
import { elementEnd } from "@angular/core/src/render3";
import { DownloadFileComponent } from "@app/components/download-file/download-file.component";
import { ApiService, AppSettingsService } from "@app/services";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
interface Appliance {
  action: string,
  args: string[]
}
@Component({
  selector: "main-home",
  templateUrl: "./main-home.component.html",
  styleUrls: ["./main-home.component.scss"]
})
export class MainHomeComponent implements OnInit {
  @ViewChild('modalResult', {read: TemplateRef}) modalResult: TemplateRef<any>;
  @ViewChild('modalExecuteAction', {read: TemplateRef}) modalExecuteAction: TemplateRef<any>;
  title = 'BerOp';
  actionsList: any
  action: string | null;
  items: Appliance[] = []

  constructor(private appSettingsService: AppSettingsService, private modalService: NgbModal) { }

  async ngOnInit() {
    await this.appSettingsService.getJSON().then(async (res) => {
      this.actionsList = res.action_list;
    });
    this.items.push({ action: '', args: [] })
  }

  async chooseAction(id: any, action: string, withUpload: boolean) {
      const modalRef =  this.modalService.open(DownloadFileComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.action = action;
      modalRef.componentInstance.withUpload = withUpload? true : false;
  }
}
