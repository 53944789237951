import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

import { environment } from '@env/environment';
import { Logger } from '@app/services/logger.service';

const log = new Logger('RequestCache');

interface HttpCacheEntry {
    url: string,
    lastUpdated: number; // Date timestamp
    response: HttpResponse<any>;
}

@Injectable()
export class RequestCache {

    readonly maxAge = environment.cacheMaxAge;
    readonly cache = new Map<string, HttpCacheEntry>();

    get(req: HttpRequest<any>): HttpResponse<any> | null {
        const url = req.urlWithParams;
        const entry = this.cache.get(url);
        if (!entry) {
            return null;
        }

        const isExpired = (Date.now() - entry.lastUpdated) > this.maxAge;
        if (isExpired)
            return null;

        log.debug(`Return response from cache for: ${url}`);
        return entry.response;
    }

    put(req: HttpRequest<any>, response: HttpResponse<any>): void {
        const url = req.urlWithParams;
        const entry = { url, response, lastUpdated: Date.now() };
        this.cache.set(url, entry);

        log.debug(`Store response to cache for: ${url}`);

        // Remove expired ones
        const expired = Date.now() - this.maxAge;
        this.cache.forEach(expiredEntry => {
            if (expiredEntry.lastUpdated < expired) {
                this.cache.delete(expiredEntry.url);
            }
        });
    }
}