import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '@app/services';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {
  @ViewChild('labelImport', { read: ElementRef })
  labelImport: ElementRef;
  result: any = null;
  isReady : boolean;
  closeResult: string;
  loading: boolean = false; // Flag variable
  file: File | null = null; // Variable to store file
  configFile: any;
  resultUpload = '';
  @Input() id: number;
  @Input() action: String;
  @Input() withUpload: boolean;
  constructor(private apiService: ApiService, public activeModal: NgbActiveModal, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (!this.withUpload) {
      this.isReady = true;
    }
  }
  ngAfterViewInit() {
  }


  handleFileInput(files: File[]) {
    this.loading = !this.loading;
    this.result = null
    if (files.length === 0) {
      return;
    }
    const reader = new FileReader();
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map((f) => f.name)
      .join(', ');
    reader.onloadend = (_event) => {
      if (reader && reader.result) {
        this.configFile = reader.result.toString();
      }
    };
    reader.readAsText(files[0]);
    this.loading = false;
  }

  changeListener($event): void {
    this.result = null
    this.file = $event.target.files[0]
    this.handleFileInput($event.target.files);
  }

  async onUpload() {
    this.loading = !this.loading;
     let res : any;
     const files: FileList = this.labelImport.nativeElement.files;
     try {
       res = await this.apiService.upload(this.file, true);
       console.log(res)
       if (res.trim() === 'The file is not uploaded yet') {
        res = await this.apiService.upload(this.file, false);
       }

       this.resultUpload = res;
     } catch (error) {
       console.log('Error', error);
     }
    this.loading = false;
    this.isReady = true;
  }

  async lanchAction() {
    this.loading = true;
    this.result = null
    this.resultUpload = '';
    let fileToDownload = '';
    let file = '';
    let res;
    if (this.withUpload) {
      res = await this.apiService.PostActionsUpload(this.id, '{"-f":"'.concat('', this.file?this.file.name: '').concat('"}'))
    } else {
      res = await this.apiService.PostActions(this.id)
    }
    fileToDownload = res.stdout[0]
    file = res.stdout[1]
    if (fileToDownload === 'file_directory') {
      this.downloadFile(file);
    }

    // Parse HTML_OUTPUT
    let htmlout = ""
    for (var i = 0; i < res.stdout.length; i++) {
      let line = res.stdout[i]
      if (line.startsWith('HTML_OUTPUT: ')) {
        htmlout = htmlout + line.replace('HTML_OUTPUT: ', '')
      }
    }

    this.result = {
      action: this.id, 
      stderr: res.stderr, 
      stdout: res.stdout ,
      htmlout: this.sanitizer.bypassSecurityTrustHtml(htmlout)
    }
    this.loading = false;
    this.isReady = false
  }

  async downloadFile(path: string) {
    this.loading = !this.loading;
    if (path) {
      await this.apiService.DownloadFiles(path)
      this.loading = false;
    }
  }
}
