import { Inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';

import { Logger } from '@app/services/logger.service';


declare var require: any
const FileSaver = require('file-saver');
const log = new Logger('ApiService');

enum ApiMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE'
}

interface ListResult<T> {
    result: T[];
}

export interface AuthToken {
    token: string;
    refreshToken: string;
}

export interface Alert {
    type: string;
    message: string;
}

@Injectable()
export class ApiService {

    private readonly baseUrl : string;

  constructor(private httpClient: HttpClient,  @Inject('API_URL') apiUrl: string, ) {
      this.baseUrl = apiUrl
    }

    login(username: string, password: string): Observable<AuthToken> {
        const params = { username, password };
        return this.callApiObservable<AuthToken>(ApiMethod.POST, '/auth/api-token-auth/', params);
    }

    refreshToken(refreshToken: string): Observable<AuthToken> {
        const params = { refreshToken };
        return this.callApiObservable<AuthToken>(ApiMethod.POST, '/auth/refresh-token/', params);
    }

    private callApi<T>(method: ApiMethod, path: string, params?: any, token?: string): Promise<T> {
      return this.callApiObservable<T>(method, path, params, token).toPromise();
    }

    private callApiObservable<T>(method: ApiMethod, path: string, params?: any, token?: string): Observable<T> {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json; charset = utf-8;' });

      const url = path;
      const hasBody = method === ApiMethod.POST || method === ApiMethod.PUT || method === ApiMethod.PATCH;
      const options = {
        headers,
        body: hasBody ? params : undefined,
        params: !hasBody ? params : undefined,
      };

      const logMethodPrefix = `${method} ${path}`;
      log.debug(`Calling - ${logMethodPrefix}`);

      return this.httpClient.request<T>(method.toString(), url, options).pipe(
        tap((x) => log.debug(`Succeed - ${logMethodPrefix}`, x)),
        catchError((e) => {
          log.error(`Failed - ${logMethodPrefix}`, e);
          return throwError(e);
        })
      );
    }

  async PostActions(id: number): Promise<any>{
    return await this.callApi<any>(ApiMethod.POST,`${this.baseUrl}/api/jobs/`+id,{})
  }

  async PostActionsUpload(id: number, file?: string): Promise<any>{
    return await this.callApi<any>(ApiMethod.POST,`${this.baseUrl}/api/jobs/`+id,file)
  }

  async DownloadFiles(FilePath: string) {
    FileSaver.saveAs(`${this.baseUrl}/static/`+FilePath, FilePath);
  }


  byteToHexString(uint8arr) {
    if (!uint8arr) {
      return '';
    }
    
    var hexStr = '';
    for (var i = 0; i < uint8arr.length; i++) {
      var hex = (uint8arr[i] & 0xff).toString(16);
      hex = (hex.length === 1) ? '0' + hex : hex;
      hexStr += hex;
    }
    
    return hexStr.toUpperCase();
  }

  async upload(file, hashOnly): Promise<any> {
    const formData: FormData = new FormData();
    if (hashOnly == false) {
      formData.append('file', file, file.name);
    } else {
      formData.append('file_name', file.name);
      const arrayBuffer = await file.arrayBuffer()
      const msgUint8 = new Uint8Array(arrayBuffer)
      
      let hashBuffer = await window.crypto.subtle.digest('SHA-256', msgUint8);
      const hashArray = Array.from(new Uint8Array(hashBuffer));     
      const fileHash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      formData.append('file_hash', fileHash);  
    }

    return this.httpClient.post(`${this.baseUrl}/cgi-bin/upload.py`, formData, { responseType: 'text' }).toPromise();
  }
}
